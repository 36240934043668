import React from 'react';
import Layout from '../components/Layout';
import { t } from '../utils/lang-helper';
import './download.scss';

export default class Download extends React.Component {
  constructor(props) {
    super(props);

    let downloadId = null;
    let downloadLink = '/';

    if (typeof window !== 'undefined') {
      let urlParams = new URLSearchParams(window.location.search);
      downloadId = urlParams.get('id');

      switch (downloadId) {
        case 'sustainability-report': {
          downloadLink = 'https://isko.cdn.prismic.io/isko/665fb42e-5e5e-41f1-9b38-158bc0bc7edc_ISKO+Sustainability+report+2021+Executive+Summary.pdf';
          break;
        }
      }
    }

    this.state = {
      pageLang: 'en-gb',
      downloadId,
      downloadLink
    };
  }

  render() {
    const {
      state: {
        pageLang,
        downloadId,
        downloadLink
      }
    } = this;

    if (typeof window !== 'undefined') {
      try {
        window.dataLayer.push({
          'event': 'download',
          'data': {
            id: downloadId
          }
        });
      } catch (e) {
      }

      setTimeout(() => {
        document.location = downloadLink;
      }, 1000);
    }

    return (<Layout pageLang={pageLang}>
      <div className="page-download">
        <div className="Wrapper">
          <h1>
            {t('Your download will start in a moment.')}
          </h1>
        </div>
      </div>
    </Layout>);
  }
}

